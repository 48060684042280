import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import Head from 'next/head';
import Router from 'next/router';
import PropTypes from 'prop-types';
import React from 'react';
import { AuthProvider } from '~/hooks/useAuthentication';
import { cancelAllRequest, setUrl } from '~/helpers/browser';
import useQueryParams from '../hooks/useQueryParams';
import useSEO from '../hooks/useSEO';
import theme from '../themes/default';
import Script from 'next/script';
import { PaymentsModalProvider } from '~/components/PaymentsModal';
import '~/styles/global.css';
// import '@front10-devs/canvas-video-next/dist/index.css';
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import TutorialStateProvider from '../components/TutorialVideos/TutorialStateProvider';
import { GTMHeadScript } from '~/components/GTM';
import { BannerContextProvider } from '~/components/Banner';
import Banner from '~/components/Banner';
import VerifyUser from '~/components/VerifyUser';
import * as Sentry from '@sentry/nextjs';
import { useAccessTokenInit } from '~/services/security.service';

const onRedirectCallback = (appState) => {
  // Use Next.js's Router.replace method to replace the url
  Router.replace(appState?.returnTo ?? '/dashboard');
};

const Application = ({ Component, pageProps }) => {
  const params = useQueryParams();
  const seo = useSEO(pageProps);
  // Use the layout defined at the page level, if available
  const Layout = Component.Layout || React.Fragment;

  React.useEffect(() => {
    setUrl('override_referrer', null);
    setUrl('stripe_success', null);

    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  return (
    <Auth0Provider
      domain={process.env.NEXT_PUBLIC_AUTH0_DOMAIN}
      clientId={process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID}
      audience={process.env.NEXT_PUBLIC_AUTH0_AUDIENCE}
      scope={process.env.NEXT_PUBLIC_AUTH0_SCOPE}
      redirectUri={typeof window !== 'undefined' && window.location.origin}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens={true}
    >
      <InitToken>
        <AuthProvider stripeSuccess={params.stripe_success}>
          <Head>
            <meta charSet="utf-8" key="charSet" />
            <meta
              name="viewport"
              content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
            />
            <meta name="robots" content="index, follow" />
            <meta property="og:locale" content="en_US" />
            <meta property="og:type" content="website" />
            <meta property="og:site_name" content="WOXO" />
            <link rel="shortcut icon" href="/static/favicon.ico" />
            <title>{seo.title}</title>
            <link rel="canonical" href={seo.canonical} />
            <meta name="description" content={seo.description} />
            <meta name="keywords" content={seo.keywords} />
            <meta property="og:title" content={seo['og:title']} />
            <meta property="og:url" content={seo['og:url']} />
            <meta property="og:description" content={seo['og:description']} />
            <meta property="og:image" content={seo['og:image']} />
            <meta property="og:image:width" content={seo['og:image:width']} />
            <meta property="og:image:height" content={seo['og:image:height']} />
            <meta name="twitter:card" content={seo['twitter:card']} />
            <meta name="twitter:title" content={seo['twitter:title']} />
            <meta property="twitter:url" content={seo['twitter:url']} />
            <meta name="twitter:description" content={seo['twitter:description']} />
            <meta name="twitter:image" content={seo && seo['twitter:image']} />
            <meta name="theme-color" content={theme.palette.primary.main} />
          </Head>
          <GTMHeadScript />
          <ThemeProvider theme={theme}>
            <PaymentsModalProvider>
              <BannerContextProvider>
                <TutorialStateProvider>
                  <CssBaseline />
                  <Banner />
                  <Layout>
                    <Component {...pageProps} />
                  </Layout>
                </TutorialStateProvider>
              </BannerContextProvider>
            </PaymentsModalProvider>
          </ThemeProvider>
        </AuthProvider>
      </InitToken>
    </Auth0Provider>
  );
};

function InitToken({ children }) {
  const user = useAccessTokenInit();
  return !user || (user && user.email_verified) ? children : <VerifyUser />;
}

Application.propTypes = {
  Component: PropTypes.func.isRequired,
  pageProps: PropTypes.objectOf(PropTypes.any)
};

Application.defaultProps = {
  pageProps: {}
};

Router.onRouteChangeStart = (url) => {
  cancelAllRequest();
};

export default Application;
